import * as Y from 'yjs'
import { WebrtcProvider } from 'y-webrtc'
import { MonacoBinding } from 'y-monaco'
import EditorComp, { useMonaco } from '@monaco-editor/react';
import {  useRef } from 'react'


// There are some steps missing to initialize the editor
// The editor requires a webpack build-step
// See the complete example:
//   https://github.com/yjs/yjs-demos/blob/master/monaco/monaco.js

function Editor({code, setCode, room}) {
    const monaco = useMonaco();
    const editorRef = useRef(null)
    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }
      
    function handleEditorDidMount(editor, monaco) {
        editorRef.current = editor;
        const ydoc = new Y.Doc()
        const provider = new WebrtcProvider(room,ydoc, { signaling: [process.env.REACT_APP_WS,`ws${location.protocol.slice(4)}//${location.host}`] })
        const type = ydoc.getText(room)
        const awareness =provider.awareness
        awareness.setLocalStateField('user', {
            // Define a print name that should be displayed
            name: 'Emmanuelle Charpentier'+Math.random()*100,
            // Define a color that should be associated to the user:
            color: getRandomColor() // should be a hex color
          })
        const monacoBinding = new MonacoBinding(
            type,
            editor.getModel(),
            new Set([editor]),
            provider.awareness
        )
      }

    return <EditorComp theme='vs-dark' height='inherit' onChange={setCode}  onMount={handleEditorDidMount} value={code} defaultLanguage="javascript" />;
}
export default Editor;
