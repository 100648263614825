import { useContext, useState } from "react";
import { UserContext } from "./UserContext";
import { useNavigate } from "react-router-dom";

function CreateRoom () {
    const { user, setUser } = useContext(UserContext);
    const [room, setRoom] = useState('')
    const navigate = useNavigate();
    const handleSubmit=(e)=>{
        e.preventDefault()
        let roomId = Math.random().toString(26).slice(7)
        navigate(roomId)
    }
    return (<div>
        <form onSubmit={handleSubmit}>
            {/* <input placeholder="Username" type="text" value={user} onChange={(e)=>setUser(e.target.value)}/>
            <input placeholder="Room Name" type="text" value={room} onChange={(e)=>setRoom(e.target.value)}/> */}
            <button type="submit">Create Random Room</button>
        </form>
    </div>)
}
export default CreateRoom;