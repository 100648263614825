import { useParams } from 'react-router-dom';
import Editor from './Editor';
import Output from './Output';
import { useState } from 'react';

function Room() {
  const [code, setCode] = useState('')
  let { room } = useParams();
  return (
    <>
      <Editor room={room} code={code} setCode={setCode}/>
      <Output code={code}/>
    </>
  );
}

export default Room;
