import { useEffect, useState } from "react";
import ConsoleExtension from "./components/ConsoleExtension";
import ResultView from "./components/ResultView";
function createWindowSnapshot() {
  return Object.keys(window);
}
const notTobeDeleted = [
  '0',
  'require',
  'monaco',
  'addEventListener',
  'removeEventListener',
  'dispatchEvent'
]
function maskedEval(scr, snapshotBefore)
{
  function clearCustomVariables() {
    try{
    for (const prop in window) {
      // console.log(prop.includes(snapshotBefore))
      if (!snapshotBefore.includes(prop) && prop!=='AMDLoader'&& !notTobeDeleted.includes(prop) && !prop.startsWith('__REACT_')) {
        // console.log('deleted', prop)
        // toBeDeleteArray.push(prop)
       window[prop]=undefined;
      }
    }
  }catch(err){
      // console.log(err)
    }
  }
  clearCustomVariables()
  eval.call({},`(async () => { ${scr} })();`);
}

function Output({code}){
    const [result, setResult] = useState([]);
    const [error, setError] = useState(undefined);
    const [snapshotBefore, setSnapShot] = useState([]);
    
    const addLogs = (log) => {
        setResult((state) => [...state, log]);
      };
      const onExecuteClick = () => {
        if (code) {
          try {
            setResult([]);
            // setCode(code);
            let result = maskedEval(code,snapshotBefore)
            if (
              result &&
              typeof result !== "function" &&
              typeof result !== "object"
            ) {
              addLogs(result);
            }
            setError(undefined);
          } catch (e) {
            
            console.log(e)
          }
        }
      };
      useEffect(()=>{
         setSnapShot(createWindowSnapshot())
      },[])
    return(
        <div className="output">
        <ConsoleExtension addLogs={addLogs} />
        <header className="control-panel">
        <div>Console </div> <button onClick={onExecuteClick}>Execute</button>
        </header>
        <ResultView result={result} onExecuteClick={onExecuteClick} />
        </div>
    )
}

export default Output;
