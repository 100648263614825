import React from "react";
import { Inspector, chromeDark } from 'react-inspector';

const ResultView = (props) => {
  const getLogElements = () => {
    if (props.result && props.result.length > 0) {
      return props.result.map((item, index) => {
        // window.temp(item)
        return item.map((comp,id)=>(<div style={{paddingTop:'10px', paddingBottom:'10px', borderBottom: '1px solid #4a4a4a'}} key={`result-${index}-${id}`}><Inspector  expandLevel={comp instanceof Error ? 1:0} showNonenumerable={comp instanceof Error} theme={{...chromeDark, ...({ BASE_BACKGROUND_COLOR: 'rgb(30,30,30)' })}}  data={comp}/></div>))
      });
    }

    return null;
  };

  return (
    <React.Fragment>
      <div className="view-wrapper">
       
        <div className="view result">{getLogElements()}</div>
      </div>
    </React.Fragment>
  );
};

export default ResultView;