import './App.css';
import { useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import CreateRoom from './CreateRoom';
import Room  from './Room';
import { UserContext } from './UserContext';
const router = createBrowserRouter([
  {
    path: "/",
    element: <CreateRoom/>,
    children: [
    ]
  },
  {
    path: '/:room',
    element: <Room/>
  }
]);
function App() {
  const [user, setUser]= useState('')
  return (
    <div className="App">
      <UserContext.Provider value={{user, setUser}}>
       <RouterProvider router={router} />
      <div className='developer'>Made with ❤️ by <a href='https://devaman.dev' target='__blank'>Amit</a></div>

       </UserContext.Provider>
    </div>
  );
}

export default App;
