import * as React from "react";
class ConsoleExtension extends React.Component{
  /**
   * constructor for console
   * @param props props
   * @param state state
   */
  constructor(props, state) {
    super(props, state);
    let that = this;
    window.temp = console.log
    // override/tweek default console.log
    console.log = function (...value) {
      let log = "";
      if (typeof value === "object") {
        log = value;
      } else {
        log = value;
      }
      that.props.addLogs(log);
    };
  }

  render() {
    return null;
  }
}

export default ConsoleExtension;